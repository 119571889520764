import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useTranslation } from 'react-i18next'

import SEO from "@components/Seo"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
// import Header from "@components/blocks/global/Header"
import PageHeader from "@components/blocks/common/PageHeader"
import PageSectionTitle from "@components/blocks/common/PageSectionTitle"
// import OfficialMaterial from "@components/blocks/gallery/OfficialMaterial"
import OfficalWorks from "@components/blocks/index/OfficialWorks"
// import DetailBanner from "@components/blocks/comic/DetailBanner"
// import CharaIntro from "@components/blocks/comic/CharaIntro"

import ContentList from "@components/blocks/comic/ContentList"

import css from "@css/components/blocks/common/Page.module.styl"

export default function Comic({data})
{
    const {t} = useTranslation()
	const { allWordpressWpIllust, allWordpressWpComics, allWordpressWpMovie } = data
	const illustData = allWordpressWpIllust.edges
	const comicsData = allWordpressWpComics.edges
	const movieData = allWordpressWpMovie.edges

	return (
		<React.Fragment>
			<SEO title={'COMICS'} />
			<main className={css.container} id="main">
				<PageHeader l1="COMICS" l2={t('comics__headerTitle2')}/>
				<div className={css.content}>
					<div className={css.comic}>
                        <section className={css.section}>
                            {/* <DetailBanner/> */}
                            <ContentList data={comicsData} noTitle/>
                        </section>
                        <section className={css.section}>
                            <PageSectionTitle l1={t('index__supportTitleOfficialWorks')} l2={t('index__officialWorksTitle2')} />
                            <OfficalWorks contentMode />
                        </section>
					</div>
				</div>
			</main>
			<FooterSns/>
			<Footer/>
		</React.Fragment>
	)
}

export const pageQuery = graphql`
    query {
        allWordpressWpIllust (sort: { fields: date, order: DESC }){
            edges {
            	node {
            		id
            		slug
            		title
            		content
            		date(formatString: "MMMM DD, YYYY")
            		featured_media {
            			source_url
                        localFile {
                            publicURL
                            childImageSharp {
                                high: fluid(maxWidth: 1440) {
                                    ...GatsbyImageSharpFluid
								}
								middle: fluid(maxWidth: 720) {
                                    ...GatsbyImageSharpFluid
                                }
                                thumbnail: fluid(maxWidth: 480, cropFocus: CENTER, fit: COVER) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            		}
            	}
            }
        }
        allWordpressWpMovie (sort: { fields: date, order: DESC }) {
            edges {
            	node {
            		id
            		slug
            		title
            		content
            		date(formatString: "MMMM DD, YYYY")
            		featured_media {
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1280) {
                                    ...GatsbyImageSharpFluid
                                }
                                thumbnail: fluid(maxWidth: 640) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            		}
                    acf {
                        movie_youtube_id
                    }
            	}
            }
        }
        allWordpressWpComics (sort: { fields: date, order: ASC }) {
            edges {
            	node {
            		id
            		slug
            		title
            		content
					date(formatString: "MMMM DD, YYYY")
					path
            		featured_media {
            			source_url
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1280) {
                                    ...GatsbyImageSharpFluid
                                }
                                thumbnail: fluid(maxWidth: 640) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            		}
                    acf {
                        comics_url
                    }
            	}
            }
        }
    }
`